<template>
  <div v-if="permissions">
    <div v-if="permissions['remessa.index'].permissao">
      <b-row
        align-h="between"
        class="mb-1"
      >
        <b-col>
          <b-button
            v-b-modal.filtro
            variant="warning"
          >
            Filtro
          </b-button>
        </b-col>
        <b-col
          v-if="permissions['remessa.store'].permissao"
          class="d-flex justify-content-end"
        >
          <b-button
            v-b-modal.modal-prevent-closing
            variant="primary"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Nova Remessa</span>
          </b-button>
        </b-col>
      </b-row>
      <b-modal
        id="filtro"
        ref="filtro"
        title="Filtro de Remessas"
        no-close-on-backdrop
        ok-title="Filtrar"
        cancel-variant="danger"
        cancel-title="Cancelar"
        @show="limparModal"
        @ok="setFilter"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-1"
          >
            <label
              class="h5 "
              for="CREDOR"
            >
              Credor
            </label>
            <v-select
              id="CREDOR"
              v-model="credor_filter"
              :options="credores"
            />
          </b-col>
          <b-col
            cols="12"
            class="mb-1"
          >
            <label
              for="data_entrada"
              class="h5"
            >Data Entrada</label>
            <b-form-input
              id="data_entrada"
              v-model="data_entrada"
              locale="pt"
              type="date"
            />
          </b-col>
        </b-row>
      </b-modal>
      <!-- modal -->
      <b-modal
        id="modal-prevent-closing"
        ref="my-modal"
        no-close-on-backdrop
        title="Importar Nova Remessa"
        cancel-title="Cancelar"
        ok-title="Importar"
        cancel-variant="danger"
        @hidden="limparModal"
        @show="limparModal"
        @ok="handleOk"
      >
        <card-loading
          ref="refreshCard"
          action-refresh
        >
          <form
            ref="form"
            @submit.stop.prevent="handleSubmit"
          >
            <b-form-group
              label="Selecione o credor"
              label-for="selecione-credor"
            >
              <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="credores"
              />
            </b-form-group>
            <b-form-group
              label="Selecione o arquivo"
              label-for="name"
            >
              <b-form-file
                v-model="file"
                placeholder=""
                drop-placeholder="Arquivo"
              />
            </b-form-group>
            <div>
              <label for="example-datepicker">Data Remessa</label>
              <b-form-input
                id="example-datepicker"
                v-model="dataRemessa"
                locale="pt"
                type="date"
                class="mb-1"
              />
            </div>
            <label>Tipo da Remessa</label>
            <v-select
              v-model="tipoRemessa"
              :options="tiposRemessa"
              class="mb-2"
            />
          </form>
          <b-form-checkbox v-model="acaoRemessa">
            <span class="vs-checkbox">
              <span
                class="vs-checkbox--check"
              />
            </span>
            <span class="vs-label">Deseja gerar ação? </span>
          </b-form-checkbox>
          <div
            v-if="acaoRemessa"
            class="mt-1"
          >
            <b-form-select
              v-model="selectedAcaoRemessa"
              :options="optionsAcaoRemessa"
              @change="chooseAction"
            />
            <b-col
              md="12"
            >
              <template-sms
                v-show="tipo === 'sms'"
              />
            </b-col>
            <b-col
              md="12"
            >
              <template-email
                v-show="tipo === 'email'"
              />
            </b-col>
            <b-col
              md="12"
            >
              <template-whatsapp
                v-show="tipo === 'whatsapp'"
              />
            </b-col>
          </div>
        </card-loading>
      </b-modal>
      <b-card v-if="permissions['remessa.index'].permissao">
        <b-overlay
          :show="load"
          variant="transparent"
        >
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <b-row align-h="between">
                <b-col lg="1">
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      :options="[10, 15, 20]"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  lg="8"
                  class="d-flex justify-content-end"
                >
                  <b-form-input
                    v-model="filter"
                    type="search"
                    size="sm"
                    placeholder="Pesquisar"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-compare="mySortCompare"
                @filtered="onFiltered"
              >
                <template #cell(nome)="data">
                  <b-media v-if="permissions['remessa.credor'].permissao">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :text="getInitials(data.item.nome)"
                        :style="{backgroundColor: randomColor(data.item.id)}"
                        :to="{ name: 'remessa-view', params: { id: data.item.id }}"
                      />
                    </template>
                    <b-link
                      :to="{ name: 'remessa-view', params: { id: data.item.id }}"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ data.item.nome }}
                    </b-link>
                  </b-media>
                  <b-media v-else>
                    <template #aside>
                      <b-avatar
                        size="32"
                        :text="getInitials(data.item.nome)"
                        :style="{backgroundColor: randomColor(data.item.id)}"
                      />
                    </template>
                    {{ data.item.nome }}
                  </b-media>
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="status[1][data.value]">
                    {{ status[0][data.value] }}
                  </b-badge>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="TrashIcon"
                      size="16"
                      class="mx-1 cursor-pointer trash-icon"
                      @click="deletRemessa(data.item.id, data.item.numeroRemessa)"
                    />
                  </div>
                </template>
                <template #cell(valor_nominal)="data">
                  {{ valorBr(data.item.valor_nominal, true) }}
                </template>

              </b-table>
            </b-col>

            <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </div>
    <div v-else>
      <not-authorized />
    </div>
    <!-- button -->
  </div>

</template>

<script>
import {
  BOverlay,
  BTable,
  BAvatar,
  BMedia,
  BLink,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton,
  BCard,
  BFormFile,
  BModal, VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
// import { onMounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import cardLoading from '@core/components/b-card-loading/BCardActions.vue'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import TemplateSms from '../campanha/cards/TemplateSms.vue'
import TemplateEmail from '../campanha/cards/TemplateEmail.vue'
import templateWhatsapp from '../campanha/cards/TemplateWhatsapp.vue'
import { util } from '../../../libs/util'
import axios from '../../../../axios-auth'

export default {
  components: {
    notAuthorized,
    BOverlay,
    BTable,
    BAvatar,
    BMedia,
    BLink,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BButton,
    BCard,
    BModal,
    BFormFile,
    vSelect,
    cardLoading,
    BFormCheckbox,
    TemplateSms,
    TemplateEmail,
    templateWhatsapp,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [util],
  data() {
    return {
      load: true,
      credor_filter: {},
      credores: [],
      data_entrada: '',
      file: null,
      selected: '',
      option: [],
      modalShow: false,
      perPage: 20,
      dataRemessa: null,
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filteredItems: null,
      filterOn: [],
      loaded: false,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Id',
        },
        {
          key: 'nome', label: 'Credor', sortable: true, class: 'text-left',
        },
        { key: 'dataRemessa', label: 'Dt Remessa', sortable: true },
        { key: 'dataImportacao', label: 'Dt Importação', sortable: true },
        { key: 'actions', label: 'Excluir' },
      ],
      colorCache: {},
      items: [],
      permissions: null,
      tiposRemessa: [
        'NORMAL',
        'AVULSA',
        'COMPLEMENTAR',
      ],
      tipoRemessa: '',
      acaoRemessa: false,
      optionsAcaoRemessa: [
        'SMS',
        'EMAIL',
        'WHATSAPP',
      ],
      selectedAcaoRemessa: '',
      tipo: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortfutable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async created() {
    // carregar remessas
    this.carregarRemessas()
    await this.execute('api/v1/credores/listar/false').then(res => {
      const dadosCredor = res.data.dados
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in dadosCredor) {
        this.credores.unshift({
          label: dadosCredor[prop].nome,
          value: dadosCredor[prop].id,
        })
      }
    })

    this.permissions = JSON.parse(localStorage.permissoes).remessa
  },
  mounted() {
    this.totalRows = this.items.length
    // this.$bvModal.show('filtro')
  },
  methods: {
    execute(url) {
      return axios.get(url, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
    },
    montaItens(dados) {
      const remessas = []
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in dados) {
        remessas.unshift({
          id: dados[prop].id,
          numeroRemessa: dados[prop].numero_remessa,
          nome: dados[prop].nome_credor,
          dataRemessa: dados[prop].data_remessa ? this.dataHora(dados[prop].data_remessa, true) : '',
          dataImportacao: dados[prop].data_entrada ? this.converterData(dados[prop].data_entrada) : '',
        })
      }
      return remessas
    },
    limparModal() {
      this.credor_filter = {}
      this.data_entrada = ''
      this.dataRemessa = ''
      this.selected = []
    },

    async setFilter() {
      this.load = true
      if (this.credor_filter != null) {
        let api = 'api/v1/remessas'
        if (this.credor_filter) {
          api += `?credor=${this.credor_filter.value}`
        }

        if (this.data_entrada.length > 0) {
          api += `&data_remessa=${this.data_entrada}`
        }

        this.execute(api).then(res => {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          this.load = false
          this.items = this.montaItens(res.data.dados)
          this.totalRows = this.items.length
        })
      } else {
        this.updateList()
      }
    },
    deletRemessa(id, remessa) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar a remessa ${remessa}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.execute(`api/v1/remessas/delete/${id}`)
            .then(res => {
              if (res.data) {
                this.updateList()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Remessa deletada com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
          this.updateList()
        }
      })
    },
    carregarRemessas() {
      this.execute('api/v1/remessas').then(res => {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        this.load = false
        this.items = this.montaItens(res.data.dados)
        this.totalRows = this.items.length
      })
    },
    updateList() {
      this.load = false
      this.execute('api/v1/remessas/create')
        .then(res => {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          this.load = false
          this.items = this.montaItens(res.data.dados)
          this.totalRows = this.items.length
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredItems = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      if (!this.file && !this.dataRemessa && !this.selected !== 0) {
        this.$swal({
          title: 'Ops!',
          text: 'Esqueceu de informar o credor, anexar o arquivo e informar a data da remessa',
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (!this.selected) {
        this.$swal({
          title: 'Ops!',
          text: 'Esqueceu selecionar o credor',
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (!this.file) {
        this.$swal({
          title: 'Ops!',
          text: 'Esqueceu anexar o arquivo',
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (!this.dataRemessa) {
        this.$swal({
          title: 'Ops!',
          text: 'Esqueceu de informar a data da remessa',
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      const formData = new FormData()
      formData.append('remessa', this.file)
      formData.append('credor_id', this.selected.value)
      formData.append('data_remessa', this.dataRemessa)
      formData.append('tipo', this.tipoRemessa)
      this.selectedAcaoRemessa.length ? formData.append('tipo_acao', this.selectedAcaoRemessa) : null
      if (this.acaoRemessa) {
        const templateKeys = {
          SMS: 'templateSms',
          EMAIL: 'templateEmail',
          WHATSAPP: 'templateWhatsapp',
        }
        const templateKey = templateKeys[this.selectedAcaoRemessa]
        if (templateKey) {
          const template = localStorage.getItem(templateKey)
          if (template) {
            formData.append('template_id', template)
          }
        }
      }
      this.$refs.refreshCard.showLoading = true
      await axios.post('api/v1/remessas/store', formData,
        {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          if (response) {
            this.file = null
          }
          this.id = response.data.dados.id
          this.file = null
          this.selected = null
          this.tipoRemessa = ''
          this.$swal({
            title: 'Importação realizada com sucesso',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$refs.refreshCard.showLoading = false
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
          this.$router.push({ name: 'remessa-view', params: { id: this.id } })
        })
        .catch(error => {
          // Error
          this.$refs.refreshCard.showLoading = false
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // eslint-disable-next-line eqeqeq
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            if (error.response.data.message === 'The given data was invalid.') {
              this.$swal({
                title: 'Formato do arquivo inválido!',
                text: 'Só aceitamos os formatos .xlx .xls .xlsx',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          }
        })
    },
    chooseAction() {
      const tipoPorAcaoRemessa = {
        SMS: 'sms',
        EMAIL: 'email',
        WHATSAPP: 'whatsapp',
      }

      this.tipo = tipoPorAcaoRemessa[this.selectedAcaoRemessa]
    },
    converterData(data) {
      const date = new Date(data)
      const dia = date.getDate().toString().padStart(2, '0')
      const mes = (date.getMonth() + 1).toString().padStart(2, '0')
      const ano = date.getFullYear().toString()
      const hora = date.getHours().toString().padStart(2, '0')
      const minuto = date.getMinutes().toString().padStart(2, '0')
      const segundo = date.getSeconds().toString().padStart(2, '0')
      const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`
      return dataFormatada
    },
    mySortCompare(itemA, itemB, key) {
      if (key === 'dataRemessa') {
        let a = itemA[key]
        let b = itemB[key]
        a = a.split('/')
        b = b.split('/')
        a = (parseInt(a[2], 10) * 10000) + (parseInt(a[1], 10) * 100) + parseInt(a[0])
        b = (parseInt(b[2], 10) * 10000) + (parseInt(b[1], 10) * 100) + parseInt(b[0])
        return a - b
      }
      if (key === 'dataImportacao') {
        const a = this.convertToDateObject(itemA[key])
        const b = this.convertToDateObject(itemB[key])
        return a - b
      }
      return false
    },
    convertToDateObject(dateString) {
      const [day, month, yearTime] = dateString.split('/')
      const [year, time] = yearTime.split(' ')
      const [hour, minute, second] = time.split(':')

      return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
