<template>
  <div>
    <b-row align-h="end">
      <b-col md="4">
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          size="sm"
          placeholder="Pesquisar"
          debounce="300"
          class="mb-1"
        />
      </b-col>
    </b-row>
    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      :items="items"
      :fields="fields"
      :filter="filter"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      @row-selected="onRowSelected"
      @filtered="onFiltered"
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="feather icon-disc primary" />
        </template>

        <template v-else>
          <i class="feather icon-circle" />
        </template>
      </template>
      <template #cell(acoes)="data">
        <feather-icon
          icon="EyeIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="viewTemplateSms(data.item.mensagem)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
      size="md"
      class="my-0"
    />
    <b-modal
      ref="openModalTemplate"
      title="Template Sms"
      ok-only
      size="lg"
    >
      <div v-html="templateSms" />
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BFormInput,
  BRow,
  BCol,
  BPagination,
} from 'bootstrap-vue'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BTable,
    BFormInput,
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      // selectedCheck: false,
      modes: ['multi', 'single', 'range'],
      fields: ['selected', 'id', 'titulo', 'acoes'],
      items: [],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      templateSms: '',
    }
  },
  created() {
    axios.get('/api/v1/template_sms', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      this.items = res.data.dados.TemplateSms
      this.totalRows = res.data.dados.TemplateSms.length
      this.items.sort((a, b) => new Date(b.created_at && b.updated_at) - new Date(a.created_at && a.updated_at))
    })
  },
  methods: {
    onRowSelected(items) {
      localStorage.setItem('templateSms', items[0].id)
      if (localStorage.getItem('templateDiscador')) {
        localStorage.removeItem('templateDiscador')
      } else if (localStorage.getItem('templateEmail')) {
        localStorage.removeItem('templateEmail')
      } else if (localStorage.getItem('templateWhatsapp')) {
        localStorage.removeItem('templateWhatsapp')
      } else if (localStorage.getItem('templateManual')) {
        localStorage.removeItem('templateManual')
      }
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    viewTemplateSms(template) {
      this.templateSms = ''
      this.templateSms = template
      this.$refs.openModalTemplate.show()
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
